.logo-heading {
  padding-top: 20px;
}

.logo-heading {
	 text-align: center;
}

h2.page-title {
	margin-top: 7px;
}

.root {
	background-color: #f6f9fc;
}